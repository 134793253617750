/** @format */

import React from "react";
import "./Header.css";
import { Typewriter } from "react-simple-typewriter";

const Header = () => {
	return (
		<div className='Header' id='home'>
			<div className='Header__left'>
				<h3>
					WELCOME TO CODE<span>α</span>LPHA
				</h3>
				<h1>
					Hi, I’m <span>Anand Tiwari</span>
				</h1>
				<h2>
					a
					<span>
						<Typewriter
							words={[
								"Full-Stack Developer.",
								"Problem Solver.",
								"App Developer.",
								"UI/UX Designer.",
							]}
							loop={0}
							cursor
							cursorStyle='|'
							typeSpeed={70}
							deleteSpeed={50}
							delaySpeed={1000}
						/>
					</span>
				</h2>
				<div className='containers'>
					<div className='btn fb-btn'>
						<a href='https://www.facebook.com/AnandisLive'>
							<i className='fa fa-facebook' aria-hidden='true' id='fb'></i>
						</a>
					</div>
					<div className='btn ig-btn'>
						<a href='https://www.instagram.com/codealpha.in/'>
							<i className='fa fa-instagram' aria-hidden='true' id='ig'></i>
						</a>
					</div>
					<div className='btn tw-btn'>
						<a href='https://github.com/AnandCodeX'>
							<i className='fa fa-github' aria-hidden='true' id='tw'></i>
						</a>
					</div>
					<div className='btn tw-btn'>
						<a href='https://github.com/AnandCodeX'>
							<i className='fa fa-youtube' aria-hidden='true' id='ut'></i>
						</a>
					</div>
				</div>
			</div>
			<div className='Header__right'>
				<img
					className='brothers'
					src={require("../../Assets/team1.jpg")}
					alt='T-Bros'
				/>
			</div>
		</div>
	);
};

export default Header;
