/** @format */

import React from "react";
import "./Contact.css";

const Contact = () => {
	return (
		<div className='C__container' id='contact'>
			<section class='C__contact'>
				<div class='C__contact-form'>
					<h2>
						Stay Connected to <span>α</span>lpha
					</h2>
					<form action='' className="form__elements">
						<input type='' placeholder='Your Name' required />
						<input
							type='email'
							name='email'
							id=''
							placeholder='E-mail'
							required
						/>
						<input type='' placeholder='write a subject' required />
						<textarea
							name=''
							id=''
							cols='30'
							rows='10'
							placeholder='your message'
							required></textarea>
						<input type='submit' name='' value='Submit' className="cbtn"/>
					</form>
				</div>
			</section>
		</div>
	);
};

export default Contact;
