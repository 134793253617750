/** @format */

import React, { useState } from "react";
import "./navebar.css";

const Navebar = () => {
	window.addEventListener("scroll", function () {
		const navebar = document.querySelector(".navebar");
		navebar.classList.toggle("active", window.scrollY > 100);
	});
	const [Mobile, setMobile] = useState(false);
	return (
		<div className='navebar' >
			<div className='navebar__left'>
				<a href='#home'>
					<span>code</span>
					<span className='alpha'>αlpha</span>
				</a>
			</div>
			<div className='navebar__right'>
				<ul
					className={Mobile ? "nav-links-mobile" : "set link temp"}
					onClick={() => setMobile(false)}>
					<li>
						<a href='#home' >HOME</a>
					</li>
					<li>
						<a href='#services'>SERVICES</a>
					</li>
					<li>
						<a href='#team'>TEAM</a>
					</li>
					<li>
						<a href='#contact'>CONTACT</a>
					</li>
				</ul>
				<button className='toggle' onClick={() => setMobile(!Mobile)}>
					{Mobile ? (
						<i className='fas fa-times close home-btn'></i>
					) : (
						<i className='fas fa-bars open'></i>
					)}
				</button>
			</div>
		</div>
	);
};

export default Navebar;
