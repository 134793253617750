/** @format */

import React from "react";
import Card from "./Card";
import "./Services.css";

const Services = () => {
	return (
		<div className='s__container' id='services'>
			<div className='s__title'>
				<h2>
					<span>α</span>lpha Services
				</h2>
			</div>
			<div className='s__card'>
				<Card />
			</div>
		</div>
	);
};

export default Services;
