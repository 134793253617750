/** @format */

import "./App.css";
import Navebar from "./Components/Navebar/Navebar";
import Header from "./Components/Header/Header";
import Services from "./Components/Services/Services";
import Team from "./Components/Team/Team";
import Contact from "./Components/Contact/Contact";

function App() {
	return (
		<div className='App'>
			<Navebar />
			<Header />
			<Services />
			<Team />
			<Contact />
		</div>
	);
}

export default App;
