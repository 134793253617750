/** @format */

import React from "react";
import Tcard from "./Tcard";
import "./Team.css";

const Team = () => {
	return (
		<div className='P__Container' id="team">
			<h2>
				<span>α</span>lpha Team
			</h2>
			<div className='P__Container--Portfolio'>
				<Tcard />
			</div>
		</div>
	);
};

export default Team;
