/** @format */

import React from "react";
import "./Tcard.css";

const Tcard = () => {
	return (
		<div className='T__Cards'>
			{/* -----CARD 1-------------- */}
			<div className='wrapper'>
				<div className='img-area'>
					<div className='inner-area'>
						<img src={require("../../Assets/anand.png")} alt='' />
					</div>
				</div>

				<div className='name'>Anand Tiwari</div>
				<div className='about'>Pro-Developer 🧑‍💻</div>
				<div className='social-icons'>
					<a href='https://www.facebook.com/AnandisLive' className='facebook'>
						<i className='fab fa fa-facebook'></i>
					</a>
					<a
						href='https://mail.google.com/mail/?view=cm&fs=1&tf=1&to={{anandtiwarilive@gmail.com}}&su=Subject&body=Body%20Text'
						className='insta'>
						<i className='fab fa fa-google'></i>
					</a>
					<a
						href='https://web.whatsapp.com/send?phone=+919405435238&amp;text=Hi, I would like to get more information..'
						className='yt'>
						<i className='fab fa fa-whatsapp'></i>
					</a>
				</div>
			</div>
			{/* -----CARD 1-------------- */}
			<div className='wrapper'>
				<div className='img-area'>
					<div className='inner-area'>
						<img src={require("../../Assets/vishal.png")} alt='' />
					</div>
				</div>

				<div className='name'>Vishal Tiwari</div>
				<div className='about'>Problem Solver😎</div>
				<div className='social-icons'>
					<a
						href='https://www.facebook.com/vishalrtwr.017'
						className='facebook'>
						<i className='fab fa fa-facebook'></i>
					</a>
					<a
						href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to={{anandtiwarilive@gmail.com}}&su=Subject&body=Body%20Text'"
						className='insta'>
						<i className='fab fa fa-google'></i>
					</a>
					<a
						href='https://web.whatsapp.com/send?phone=+919405435238&amp;text=Hi, I would like to get more information..'
						className='yt'>
						<i className='fab fa fa-whatsapp'></i>
					</a>
				</div>
			</div>
			{/* -----CARD 1-------------- */}
			<div className='wrapper'>
				<div className='img-area'>
					<div className='inner-area'>
						<img src={require("../../Assets/suraj.png")} alt='' />
					</div>
				</div>

				<div className='name'>Suraj Tiwari</div>
				<div className='about'>Marketing Star 💫</div>
				<div className='social-icons'>
					<a
						href='https://www.facebook.com/surajravitiwari/'
						className='facebook'>
						<i className='fab fa fa-facebook'></i>
					</a>
					<a
						href='https://mail.google.com/mail/?view=cm&fs=1&tf=1&to={{surajravitiwari@gmail.com}}&su=Subject&body=Body%20Text'
						className='insta'>
						<i className='fab fa fa-google'></i>
					</a>
					<a
						href='https://web.whatsapp.com/send?phone=+919405435236&amp;text=Hi, I would like to get more information..'
						className='yt'>
						<i className='fab fa fa-whatsapp'></i>
					</a>
				</div>
			</div>
			{/* -----CARD 1-------------- */}
			<div className='wrapper'>
				<div className='img-area'>
					<div className='inner-area'>
						<img src={require("../../Assets/aman.png")} alt='' />
					</div>
				</div>

				<div className='name'>Amar Tiwari</div>
				<div className='about'>Artist In The House 🧑‍🎨</div>
				<div className='social-icons'>
					<a
						href='https://www.facebook.com/profile.php?id=100052643591705'
						className='facebook'>
						<i className='fab fa fa-facebook'></i>
					</a>
					<a
						href='https://mail.google.com/mail/?view=cm&fs=1&tf=1&to={{amartiwarilive@gmail.com}}&su=Subject&body=Body%20Text'
						className='insta'>
						<i className='fab fa fa-google'></i>
					</a>
					<a
						href='https://web.whatsapp.com/send?phone=+919404703571&amp;text=Hi, I would like to get more information..'
						className='yt'>
						<i className='fab fa fa-whatsapp'></i>
					</a>
				</div>
			</div>
			{/* -----CARD 1-------------- */}
			<div className='wrapper'>
				<div className='img-area'>
					<div className='inner-area'>
						<img src={require("../../Assets/ayush.png")} alt='' />
					</div>
				</div>

				<div className='name'>Ayush Sharma</div>
				<div className='about'>Tech Geek👨‍🚀</div>
				<div className='social-icons'>
					<a
						href='https://www.facebook.com/profile.php?id=100014292404844'
						className='facebook'>
						<i className='fab fa fa-facebook'></i>
					</a>
					<a
						href='https://mail.google.com/mail/?view=cm&fs=1&tf=1&to={{ayushjsharma@gmail.com}}&su=Subject&body=Body%20Text'
						className='insta'>
						<i className='fab fa fa-google'></i>
					</a>
					<a
						href='https://web.whatsapp.com/send?phone=+917972503312&amp;text=Hi, I would like to get more information..'
						className='yt'>
						<i className='fab fa fa-whatsapp'></i>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Tcard;
