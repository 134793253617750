/** @format */

import React from "react";
import "./Card.css";

const Card = () => {
	return (
		<div className='grid'>
			{" "}
			<div class='container'>
				{/* Card 1 */}
				<div class='card'>
					<div class='face face1'>
						<div class='content'>
							<i class='fa fa-internet-explorer'></i>
							<h3>Web Development</h3>
						</div>
					</div>
					<div class='face face2'>
						<div class='content'>
							<p>
								{" "}
								We have professional Web-Application developer team along with
								the top notch Web-designers. We work on all trending
								technologies and Provide all kind of Web-Applications solutions.
							</p>
						</div>
					</div>
				</div>
				{/* Card 2 */}
				<div class='card'>
					<div class='face face1'>
						<div class='content'>
							<i class='fa fa-mobile'></i>
							<h3>App Development</h3>
						</div>
					</div>
					<div class='face face2'>
						<div class='content'>
							<p>
								{" "}
								We have well experienced  mobile application team. we provide
								solution for both android and ios platforms. we work on cross
								platform mobile app development along with all kind of
								solutions.
							</p>
						</div>
					</div>
				</div>
				{/* Card 3 */}
				<div class='card'>
					<div class='face face1'>
						<div class='content'>
							<i class='fa fa-paint-brush'></i>
							<h3>UI/UX Designer</h3>
						</div>
					</div>
					<div class='face face2'>
						<div class='content'>
							<p>
								{" "}
								We have very creative and dynamic Web Designer team. We are
								offering cutting edge and seamless designs along with complete
								user satisfaction and user friendly to use. 
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Card;
